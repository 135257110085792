import { Box, ButtonBase, Card, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import moment from 'moment-timezone'
import 'moment/locale/es';
import { Link } from 'gatsby';
import ImageDiv from '../utils/ImageDiv';

const useStyle = makeStyles(theme => ({
    azul: {
        color: theme.recargasacuba.azul,
    },
    cubacel: {
        color: theme.recargasacuba.cubacel,
    },
    received: {
        padding: theme.spacing(1)
    },
    sent: {
        background: 'transparent',
        height: 100,
        backgroundPosition: 'top',
        backgroundSize: 'cover'
    },
    veiledContainer: {
        position: 'relative',
        marginBottom: theme.spacing(2),
    },
    veiled: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        background: 'rgb(255,255,255,0.0)'
    },
    source: {
        fontStyle: 'italic',
        fontSize: 12,
    }
}))

const BlogItem = (props) => {
    const classes = useStyle();
    const { tags, date, title, image, path, source } = props.data.frontmatter;
    return (
        <Link to={path} style={{ textDecoration: 'none' }} state={{ currentPage: props.currentPage }}>
            <Card className={classes.veiledContainer}>
                <Grid container direction="column">
                    <ImageDiv fileName={image}>
                        <Grid item className={classes.sent} />
                    </ImageDiv>
                    <Grid item className={classes.received} container direction="column">
                        <Grid item>
                            <Box display="flex">
                                <Box flexGrow="1" mr={2}>
                                    <Typography variant="body1" className={classes.azul}>{tags.map(item => (`${item}, `))}</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1" color="textSecondary" noWrap>
                                        {
                                            `${moment.tz(date, 'Europe/Madrid').tz(moment.tz.guess(true)).format('DD/MMM, YYYY')}`
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" className={classes.cubacel}>{title}</Typography>
                            <Typography variant="body1" color="textSecondary" align="left" className={classes.source}>Fuente: {source}</Typography>
                            <Typography variant="body1" align="right" className={classes.azul}>Seguir leyendo →</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <ButtonBase style={{ display: 'contents' }} aria-label={title}>
                    <Box className={classes.veiled} />
                </ButtonBase>
            </Card>
        </Link>
    )
}

export default BlogItem
