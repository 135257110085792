import React from 'react'
import PropTypes from 'prop-types'
import BlogItem from './BlogItem';
import { Grid } from '@material-ui/core';

class BlogRoll extends React.Component {
  render() {
    const { data, currentPage } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    return (
      <>
        {posts &&
          posts.map(({ node: post }) => (
            <Grid item xs={12} sm={6} key={post.id}>
              <BlogItem data={post} currentPage={currentPage} />
            </Grid>
          ))}
      </>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default BlogRoll;