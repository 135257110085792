import React from 'react'
import PropType from "prop-types"
import { Link } from "gatsby"
import { Box, Button, makeStyles } from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    paginationNext: {
        textAlign: 'right',
    },
    paginationBack: {
        textAlign: 'left',
    },
    back: {
        color: theme.recargasacuba.orange
    },
    next: {
        color: theme.recargasacuba.orange
    }
}))

export default function Pagination(props) {
    const classes = useStyle();
    const { pageContext } = props
    const { previousPagePath, nextPagePath } = pageContext

    return (
        <Box display="flex">
            <Box className={classes.paginationBack}>
                {previousPagePath &&
                    <Link to={previousPagePath} style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className="back" color="secondary">
                            ← Anterior
                        </Button>
                    </Link>}
            </Box>
            <Box flexGrow="1" />
            <Box className={classes.paginationNext}>
                {nextPagePath &&
                    <Link to={nextPagePath} style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" className="next" color="secondary">
                            Siguiente →
                        </Button>
                    </Link>}
            </Box>
        </Box>
    )
}

Pagination.protoType = {
    pageContext: PropType.object.isRequired,
}
