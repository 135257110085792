import React from "react"

import Layout from "../components/layouts/MainLayout"
import SEO from "../components/utils/Seo"
import { Link, Box, Grid, Typography } from "@material-ui/core"
import BlogRoll from "../components/blog/BlogRoll"
import Pagination from '../components/blog/Pagination';
import { graphql } from 'gatsby';

const IndexPage = (props) => {
  const { data, pageContext, path } = props

  return (
    <Layout>
      <SEO title="Donde se informan los cubanos" />
      <Grid container spacing={2} style={{
        marginTop: 16,
      }}>
        <BlogRoll data={data} currentPage={path} />
        <Grid item xs={12} >
          <Pagination pageContext={pageContext} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 32 }}>
          <Typography variant="body1" color="textSecondary" align="center"><i>Si te gusta el blog regálanos un <b>Me gusta</b> en nuestra <b>página de Facebook</b></i></Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: -8 }}>
          <iframe title="Recargas a Cuba" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frecargasacubaapp%2F&tabs&width=340&height=70&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=861364457229667" width="340" height="70" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </Grid>
        <Grid item xs={12} style={{ marginTop: -24 }}>
          <Box display="flex" alignItems="center" p={2} flexDirection="column">
            <Link href="https://www.recargasacuba.app" style={{ marginTop: 4 }}>
              <Typography variant="body1" color="textPrimary" align="center">&copy;2014-2020 www.recargasacuba.app</Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            path
            title
            tags
            image
            source
          }
        }
      }
    }
  }
`
